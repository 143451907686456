import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Ultimate Value Delivery`}</h1>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/post-conversation-analysis",
          "title": "post conversation analysis"
        }}>{`post conversation analysis`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Analysis",
          "title": "🔬 Analysis"
        }}>{`🔬 Analysis`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      